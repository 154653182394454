import * as Types from 'src/generated/index'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {}
export const BrainFragmentFragmentDoc = gql`
  fragment BrainFragment on ASMAIFAAllStarsBrain {
    tokenId
    imageUrl
    animationUrl
    contractAddress
  }
`
export const CharacterFragmentFragmentDoc = gql`
  fragment CharacterFragment on ASMAIFAAllStarsCharacter {
    glbUrl
    tokenId
    imageUrl
    jpgImageUrl
    animationUrl
    blendFileUrl
    contractAddress
    config {
      ... on BossCharacterConfig {
        Family
        Genesis {
          head
          tail
        }
        Hobby
        BossType
        Accessory
      }
      ... on GeneralCharacterConfig {
        Arms
        Eyes
        Body
        Color
        Accessory
        AccentColor
        Genesis {
          head
          tail
        }
        Legs
        Hobby
        Family
        Topper
      }
    }
  }
`
export const ReserveCharacterConfigDocument = gql`
  mutation ReserveCharacterConfig(
    $characterTokenId: Int!
    $config: CharacterConfigInput!
    $walletAddress: ChainAddress!
  ) {
    reserveCharacterConfig(
      characterTokenId: $characterTokenId
      config: $config
      walletAddress: $walletAddress
    ) {
      ... on ConfigurationTokenSuccess {
        signature
        contentHash
        expiryMillis
        signedAtEpochMillis
      }
      ... on ConfigurationTokenFailure {
        reason {
          ... on AlreadyConfigured {
            _
          }
          ... on CharacterNotFound {
            _
          }
          ... on ConfigurationReserved {
            _
          }
          ... on ConfigurationAlreadyMinted {
            _
          }
          ... on InvalidConfiguration {
            reasons
          }
        }
      }
    }
  }
`
export type ReserveCharacterConfigMutationFn = Apollo.MutationFunction<
  Types.ReserveCharacterConfigMutation,
  Types.ReserveCharacterConfigMutationVariables
>

/**
 * __useReserveCharacterConfigMutation__
 *
 * To run a mutation, you first call `useReserveCharacterConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReserveCharacterConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reserveCharacterConfigMutation, { data, loading, error }] = useReserveCharacterConfigMutation({
 *   variables: {
 *      characterTokenId: // value for 'characterTokenId'
 *      config: // value for 'config'
 *      walletAddress: // value for 'walletAddress'
 *   },
 * });
 */
export function useReserveCharacterConfigMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.ReserveCharacterConfigMutation,
    Types.ReserveCharacterConfigMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.ReserveCharacterConfigMutation,
    Types.ReserveCharacterConfigMutationVariables
  >(ReserveCharacterConfigDocument, options)
}
export type ReserveCharacterConfigMutationHookResult = ReturnType<
  typeof useReserveCharacterConfigMutation
>
export type ReserveCharacterConfigMutationResult =
  Apollo.MutationResult<Types.ReserveCharacterConfigMutation>
export type ReserveCharacterConfigMutationOptions = Apollo.BaseMutationOptions<
  Types.ReserveCharacterConfigMutation,
  Types.ReserveCharacterConfigMutationVariables
>
export const GetNfTsDocument = gql`
  query GetNFTs($walletAddress: ChainAddress!) {
    getNFTs(walletAddress: $walletAddress) {
      boxes {
        image
        tokenId
        contractAddress
        attributes {
          Rarity
        }
      }
      brains {
        ...BrainFragment
      }
      characters {
        ...CharacterFragment
      }
    }
  }
  ${BrainFragmentFragmentDoc}
  ${CharacterFragmentFragmentDoc}
`

/**
 * __useGetNfTsQuery__
 *
 * To run a query within a React component, call `useGetNfTsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNfTsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNfTsQuery({
 *   variables: {
 *      walletAddress: // value for 'walletAddress'
 *   },
 * });
 */
export function useGetNfTsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetNfTsQuery,
    Types.GetNfTsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.GetNfTsQuery, Types.GetNfTsQueryVariables>(
    GetNfTsDocument,
    options
  )
}
export function useGetNfTsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetNfTsQuery,
    Types.GetNfTsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.GetNfTsQuery, Types.GetNfTsQueryVariables>(
    GetNfTsDocument,
    options
  )
}
export type GetNfTsQueryHookResult = ReturnType<typeof useGetNfTsQuery>
export type GetNfTsLazyQueryHookResult = ReturnType<typeof useGetNfTsLazyQuery>
export type GetNfTsQueryResult = Apollo.QueryResult<
  Types.GetNfTsQuery,
  Types.GetNfTsQueryVariables
>
export const GetNftByBoxIdDocument = gql`
  query GetNFTByBoxId($boxId: Int!) {
    getNFTByBoxId(boxId: $boxId) {
      ... on UnboxedBoxResponse {
        brain {
          ...BrainFragment
        }
        character1 {
          ...CharacterFragment
        }
        character2 {
          ...CharacterFragment
        }
        character3 {
          ...CharacterFragment
        }
        character4 {
          ...CharacterFragment
        }
      }
      ... on ClosedBoxResponse {
        _
      }
    }
  }
  ${BrainFragmentFragmentDoc}
  ${CharacterFragmentFragmentDoc}
`

/**
 * __useGetNftByBoxIdQuery__
 *
 * To run a query within a React component, call `useGetNftByBoxIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNftByBoxIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNftByBoxIdQuery({
 *   variables: {
 *      boxId: // value for 'boxId'
 *   },
 * });
 */
export function useGetNftByBoxIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetNftByBoxIdQuery,
    Types.GetNftByBoxIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.GetNftByBoxIdQuery,
    Types.GetNftByBoxIdQueryVariables
  >(GetNftByBoxIdDocument, options)
}
export function useGetNftByBoxIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetNftByBoxIdQuery,
    Types.GetNftByBoxIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.GetNftByBoxIdQuery,
    Types.GetNftByBoxIdQueryVariables
  >(GetNftByBoxIdDocument, options)
}
export type GetNftByBoxIdQueryHookResult = ReturnType<
  typeof useGetNftByBoxIdQuery
>
export type GetNftByBoxIdLazyQueryHookResult = ReturnType<
  typeof useGetNftByBoxIdLazyQuery
>
export type GetNftByBoxIdQueryResult = Apollo.QueryResult<
  Types.GetNftByBoxIdQuery,
  Types.GetNftByBoxIdQueryVariables
>
export const StatusDocument = gql`
  query Status {
    status {
      flags {
        isConfiguratorEnabled
      }
    }
  }
`

/**
 * __useStatusQuery__
 *
 * To run a query within a React component, call `useStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.StatusQuery,
    Types.StatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.StatusQuery, Types.StatusQueryVariables>(
    StatusDocument,
    options
  )
}
export function useStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.StatusQuery,
    Types.StatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.StatusQuery, Types.StatusQueryVariables>(
    StatusDocument,
    options
  )
}
export type StatusQueryHookResult = ReturnType<typeof useStatusQuery>
export type StatusLazyQueryHookResult = ReturnType<typeof useStatusLazyQuery>
export type StatusQueryResult = Apollo.QueryResult<
  Types.StatusQuery,
  Types.StatusQueryVariables
>
export const GetUnboxingTokenDocument = gql`
  mutation GetUnboxingToken($boxTokenId: Int!) {
    getUnboxingToken(boxTokenId: $boxTokenId) {
      ... on GetUnboxingTokenSuccess {
        contentHash {
          brain
          character1
          character2
          character3
          character4
        }
        signature
      }
      ... on GetUnboxingTokenFailure {
        reason {
          ... on BoxNotFoundForTokenId {
            tokenId
          }
        }
      }
    }
  }
`
export type GetUnboxingTokenMutationFn = Apollo.MutationFunction<
  Types.GetUnboxingTokenMutation,
  Types.GetUnboxingTokenMutationVariables
>

/**
 * __useGetUnboxingTokenMutation__
 *
 * To run a mutation, you first call `useGetUnboxingTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetUnboxingTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getUnboxingTokenMutation, { data, loading, error }] = useGetUnboxingTokenMutation({
 *   variables: {
 *      boxTokenId: // value for 'boxTokenId'
 *   },
 * });
 */
export function useGetUnboxingTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.GetUnboxingTokenMutation,
    Types.GetUnboxingTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.GetUnboxingTokenMutation,
    Types.GetUnboxingTokenMutationVariables
  >(GetUnboxingTokenDocument, options)
}
export type GetUnboxingTokenMutationHookResult = ReturnType<
  typeof useGetUnboxingTokenMutation
>
export type GetUnboxingTokenMutationResult =
  Apollo.MutationResult<Types.GetUnboxingTokenMutation>
export type GetUnboxingTokenMutationOptions = Apollo.BaseMutationOptions<
  Types.GetUnboxingTokenMutation,
  Types.GetUnboxingTokenMutationVariables
>
export const AssignDiscordRolesDocument = gql`
  mutation AssignDiscordRoles($token: String!, $signedMessage: String!) {
    assignDiscordRoles(token: $token, signedMessage: $signedMessage) {
      __typename
      ... on AssignDiscordRolesFailure {
        reason {
          __typename
        }
      }
    }
  }
`
export type AssignDiscordRolesMutationFn = Apollo.MutationFunction<
  Types.AssignDiscordRolesMutation,
  Types.AssignDiscordRolesMutationVariables
>

/**
 * __useAssignDiscordRolesMutation__
 *
 * To run a mutation, you first call `useAssignDiscordRolesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignDiscordRolesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignDiscordRolesMutation, { data, loading, error }] = useAssignDiscordRolesMutation({
 *   variables: {
 *      token: // value for 'token'
 *      signedMessage: // value for 'signedMessage'
 *   },
 * });
 */
export function useAssignDiscordRolesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.AssignDiscordRolesMutation,
    Types.AssignDiscordRolesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.AssignDiscordRolesMutation,
    Types.AssignDiscordRolesMutationVariables
  >(AssignDiscordRolesDocument, options)
}
export type AssignDiscordRolesMutationHookResult = ReturnType<
  typeof useAssignDiscordRolesMutation
>
export type AssignDiscordRolesMutationResult =
  Apollo.MutationResult<Types.AssignDiscordRolesMutation>
export type AssignDiscordRolesMutationOptions = Apollo.BaseMutationOptions<
  Types.AssignDiscordRolesMutation,
  Types.AssignDiscordRolesMutationVariables
>
