import css from '@emotion/css'
import styled from '@emotion/styled'
import { useCycle } from 'framer-motion'
import { useHistory } from 'react-router-dom'

import { Box, AppBar, Toolbar, Container } from '@mui/material'

import { Routes } from 'src/utils/routes'
import Account from 'src/modules/header/Account'
import BurgerMenu from 'src/modules/header/burger'
import { useBreakpoints } from 'src/hooks/useBreakpoints'
import { HeaderLink } from 'src/modules/header/HeaderLink'
import { transientPropCheck } from 'src/styles/utils'

import { ReactComponent as Logo } from 'src/assets/images/aifa-logo.svg'

const StyledAppBar = styled(AppBar)`
  background-color: transparent;
  z-index: 1;
`

const StyledToolbar = styled(Toolbar)(
  ({ theme }) => css`
    padding: 0 !important;
    height: ${theme.spacing(12)};
  `
)

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
`

const AifaLogo = styled(
  Logo,
  transientPropCheck
)<{ $increaseZIndex: boolean }>(
  ({ $increaseZIndex }) => css`
    width: 120px;
    z-index: ${$increaseZIndex ? 10001 : 1};

    path {
      fill: #fff;
    }

    :hover {
      cursor: pointer;
    }
  `
)

const StyledNav = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`

const Header: React.FC = () => {
  const [isBurgerOpen, toggleIsBurgerOpen] = useCycle(false, true)

  const history = useHistory()
  const { isSmallScreen, showBurgerMenu } = useBreakpoints()

  const handleBurgerToggle = () => {
    toggleIsBurgerOpen()
  }

  const handleIconClick = () => {
    history.push(Routes.Home)

    if (showBurgerMenu && isBurgerOpen) {
      toggleIsBurgerOpen()
    }
  }

  const routesConfig = [
    {
      route: Routes.Dashboard,
      label: 'Dashboard',
      exact: false,
    },
    { route: Routes.About, label: 'About', exact: true },
    { route: Routes.Faq, label: 'Faq', exact: true },
    { route: Routes.Collections, label: 'Collections', exact: true },
  ]

  return (
    <StyledAppBar position="static" elevation={0}>
      <StyledToolbar>
        <StyledContainer>
          <AifaLogo
            role="link"
            data-cy="aifa-logo"
            onClick={handleIconClick}
            $increaseZIndex={isBurgerOpen}
          />
          {!showBurgerMenu && (
            <StyledNav>
              {routesConfig.map(({ exact, route, label }) => (
                <HeaderLink
                  to={route}
                  key={route}
                  exact={exact}
                  activeClassName="active-link"
                >
                  {label}
                </HeaderLink>
              ))}
            </StyledNav>
          )}
          <Box display="flex" alignItems="center">
            {!isSmallScreen && <Account />}
            {showBurgerMenu && (
              <BurgerMenu
                routes={routesConfig}
                isBurgerOpen={isBurgerOpen}
                isSmallScreen={isSmallScreen}
                burgerToggle={handleBurgerToggle}
              />
            )}
          </Box>
        </StyledContainer>
      </StyledToolbar>
    </StyledAppBar>
  )
}

export default Header
