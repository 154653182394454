import styled from '@emotion/styled'

import CharacterWithError from 'src/components/character/CharacterWIthError'

import { ReactComponent as Shadow } from 'src/assets/images/shadow.svg'

interface CharacterWithShadowProps {
  src: string
  alt?: string
  onError?: () => void
}

const Wrapper = styled.div`
  position: relative;
`

const Character = styled(CharacterWithError)`
  z-index: 1;
  width: 100%;
`

const StyledShadow = styled(Shadow)`
  left: 50%;
  z-index: 0;
  width: 70%;
  opacity: 40%;
  bottom: -18px;
  position: absolute;
  transform: translateX(-50%);
`

const CharacterWithShadow: React.FC<CharacterWithShadowProps> = ({
  src,
  alt,
  onError,
  ...props
}) => (
  <Wrapper {...props}>
    <Character alt={alt} src={src} onError={onError} />
    <StyledShadow />
  </Wrapper>
)

export default CharacterWithShadow
