import { ethers } from 'ethers'
import { Web3Provider } from '@ethersproject/providers'
import { hexlify, toUtf8Bytes } from 'ethers/lib/utils'
import { AbstractConnector } from '@web3-react/abstract-connector'
import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletLinkConnector } from '@web3-react/walletlink-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'

// To differentiate between dev Goerli and stg Goerli
export enum ChainENVs {
  Mainnet = 'mainnet',
  Goerli = 'goerli',
  Localhost = 'localhost',
  GoerliStaging = 'goerliStaging',
}

export const RPC_URL = process.env.REACT_APP_RPC_URL as string
export const CHAIN_ENV = process.env.REACT_APP_CHAIN_ENV as ChainENVs

export const ChainENVToID: Record<ChainENVs, number> = {
  mainnet: 1,
  goerli: 5,
  localhost: 31337,
  goerliStaging: 5,
}

export const CHAIN_ID = ChainENVToID[CHAIN_ENV]

export enum Chains {
  Mainnet = 'mainnet',
  Goerli = 'goerli',
  Localhost = 'localhost',
}

export const ChainIdToName: Record<number, Chains> = {
  1: Chains.Mainnet,
  5: Chains.Goerli,
  31337: Chains.Localhost,
}

export const CHAIN_NAME = ChainIdToName[CHAIN_ID]

export const POLLING_INTERVAL = 12000

const CONNECTOR_STORAGE_KEY = 'connector'

export const getConnectorName = () => {
  const connector = localStorage.getItem(CONNECTOR_STORAGE_KEY)

  return connector ? (connector as ConnectorNames) : null
}

export const setConnectorToStorage = (connectorName: ConnectorNames) => {
  localStorage.setItem(CONNECTOR_STORAGE_KEY, connectorName)
}

export const removeConnectorFromStorage = () => {
  localStorage.removeItem(CONNECTOR_STORAGE_KEY)
}

export enum ConnectorNames {
  Coinbase = 'Coinbase',
  MetaMask = 'MetaMask',
  WalletConnect = 'WalletConnect',
}

type Connectors = Record<ConnectorNames, AbstractConnector>

export const getLibrary = (provider: any): Web3Provider => {
  const library = new Web3Provider(provider)
  library.pollingInterval = POLLING_INTERVAL

  return library
}

export const signMessage = async (
  provider: any,
  account: string,
  message: string
): Promise<string> => {
  // We have to use WC method to sign https://github.com/WalletConnect/walletconnect-monorepo/issues/462
  if (provider.provider?.wc) {
    const wcMessage = hexlify(toUtf8Bytes(message))
    const signature = await provider.provider?.wc.signPersonalMessage([
      wcMessage,
      account,
    ])

    return signature
  }

  return provider.getSigner(account).signMessage(message)
}

const metamask = new InjectedConnector({
  supportedChainIds: [CHAIN_ID],
})

const walletconnect = new WalletConnectConnector({
  qrcode: true,
  rpc: { [CHAIN_ID]: RPC_URL },
  pollingInterval: POLLING_INTERVAL,
  bridge: 'https://bridge.walletconnect.org',
})

const coinbase = new WalletLinkConnector({
  url: RPC_URL,
  appName: 'AIFA',
})

export const connectors: Connectors = {
  [ConnectorNames.MetaMask]: metamask,
  [ConnectorNames.Coinbase]: coinbase,
  [ConnectorNames.WalletConnect]: walletconnect,
}

export const simpleRpcProvider = new ethers.providers.JsonRpcProvider(RPC_URL)
