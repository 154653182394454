import styled from '@emotion/styled'

import Background from 'src/components/layout/Background'
import ShieldLoadingIcon from 'src/components/icons/ShieldLoadingIcon'

const StyledBackground = styled(Background)`
  display: flex;
  align-items: center;
  justify-content: center;
`

const PageLoading: React.FC = () => (
  <StyledBackground>
    <ShieldLoadingIcon />
  </StyledBackground>
)

export default PageLoading
