import create from 'zustand'

export interface Toast {
  message: string
  severity: 'error' | 'success'
}

interface UseToastState extends Toast {
  isToastOpen: boolean
  closeToast: () => void
  openToast: (data: Toast) => void
}

export const useToast = create<UseToastState>(set => ({
  message: '',
  severity: 'error',
  isToastOpen: false,
  closeToast: () => set(state => ({ ...state, isToastOpen: false })),
  openToast: data => set(state => ({ ...state, ...data, isToastOpen: true })),
}))
