import css from '@emotion/css'
import styled from '@emotion/styled'
import { useState, MouseEvent } from 'react'

import { ExpandMore } from '@mui/icons-material'
import { Box, Popover, Divider, Typography } from '@mui/material'

import { useWallet } from 'src/hooks/useWallet'
import Button from 'src/components/button/Button'
import { formatAccount } from 'src/utils/helpers'
import { getConnectorName } from 'src/utils/web3'
import CopyIcon from 'src/components/icons/CopyIcon'
import { transientPropCheck } from 'src/styles/utils'
import { useWalletsDialog } from 'src/hooks/store/useWalletDialog'

import { ReactComponent as Wallet } from 'src/assets/images/wallet.svg'

const StyledButton = styled(Button)(
  ({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      width: 100%;
      margin-right: ${theme.spacing(3)};
    }
  `
)

const ConnectedButton = styled(StyledButton)(
  ({ theme }) => css`
    background-color: #fff;
    color: ${theme.palette.primary.main};

    .MuiButton-iconSizeMedium > *:first-of-type {
      font-size: ${theme.typography.pxToRem(24)};
    }

    ${theme.breakpoints.down('sm')} {
      width: 100%;
      margin-right: ${theme.spacing(3)};
    }
  `
)

const WalletIcon = styled(Wallet)(
  ({ theme }) => css`
    path {
      stroke: ${theme.palette.primary.main};
    }
  `
)

const ExpandIcon = styled(
  ExpandMore,
  transientPropCheck
)<{ $isExpanded: boolean }>(
  ({ $isExpanded }) => css`
    transition: transform 0.3s ease-out;
    transform: rotate(${$isExpanded ? '180deg' : '0'});
  `
)

const AccountPopover = styled(Popover)(
  ({ theme }) => css`
    .MuiPopover-paper {
      width: 246px;
      padding: ${theme.spacing(2)};
      margin: ${theme.spacing(1, 0, 0)};

      ${theme.breakpoints.down('sm')} {
        width: 230px;
      }
    }

    h2,
    p {
      color: ${theme.palette.text.mid};
    }

    /* Show inside the burger menu */
    ${theme.breakpoints.down('sm')} {
      z-index: 10001;
    }
  `
)

const AccountNumberContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    border-radius: 4px;
    align-items: center;
    justify-content: space-between;
    padding: ${theme.spacing(1)};
    background-color: ${theme.palette.secondary.main};
  `
)

const StyledDivider = styled(Divider)(
  ({ theme }) => css`
    margin: ${theme.spacing(1.5, 0)};
  `
)

const CopyText = styled(Typography)`
  display: flex;
  cursor: pointer;
`

const ActionText = styled(Typography)`
  cursor: pointer;
  text-decoration: underline;
`

interface AccountInfoProps {
  onConnectClick?: () => void
}

const AccountInfo: React.FC<AccountInfoProps> = ({ onConnectClick }) => {
  const [copied, setCopied] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const { active, account, disconnectWallet } = useWallet()
  const { openWalletsDialog } = useWalletsDialog()

  const connectorName = getConnectorName()

  const handleOpenWalletsDialog = () => {
    onConnectClick && onConnectClick()
    openWalletsDialog()
  }

  const openMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const closeMenu = () => {
    setAnchorEl(null)
  }

  const handleDisconnectWallet = () => {
    disconnectWallet()
    closeMenu()
  }

  const handleCopy = () => {
    setCopied(true)
    account && navigator.clipboard.writeText(account)

    setTimeout(() => {
      setCopied(false)
    }, 1000)
  }

  return active ? (
    <>
      <ConnectedButton
        color="inherit"
        onClick={openMenu}
        variant="contained"
        endIcon={<ExpandIcon $isExpanded={Boolean(anchorEl)} />}
        startIcon={<WalletIcon height="24" width="24" />}
      >
        Connected
      </ConnectedButton>
      <AccountPopover
        keepMounted
        id="account-menu"
        onClose={closeMenu}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
      >
        <Typography component="h2" variant="h4">
          Account
        </Typography>
        <StyledDivider />
        <Typography gutterBottom component="p" variant="caption">
          Connected with {connectorName}
        </Typography>
        <AccountNumberContainer>
          <Typography component="p" variant="caption">
            {formatAccount(account)}
          </Typography>
          <CopyText
            tabIndex={0}
            role="button"
            color="primary"
            variant="caption"
            onClick={handleCopy}
          >
            {copied ? 'Copied' : 'Copy'}
            <CopyIcon copied={copied} />
          </CopyText>
        </AccountNumberContainer>
        <StyledDivider />
        <Box display="flex" justifyContent="center">
          <ActionText
            role="button"
            tabIndex={0}
            color="primary"
            variant="caption"
            onClick={handleDisconnectWallet}
          >
            Disconnect
          </ActionText>
        </Box>
      </AccountPopover>
    </>
  ) : (
    <StyledButton
      color="inherit"
      variant="outlined"
      onClick={handleOpenWalletsDialog}
    >
      Connect Wallet
    </StyledButton>
  )
}

export default AccountInfo
