import css from '@emotion/css'
import { Box } from '@mui/material'
import styled from '@emotion/styled'

import { transientPropCheck } from 'src/styles/utils'

import { ReactComponent as Copy } from 'src/assets/images/copy.svg'
import { ReactComponent as Check } from 'src/assets/images/check.svg'

const copyIconCss = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  stroke-dasharray: 50;
  transition: all 300ms ease-in-out;
`

const CopyIconSvg = styled(
  Copy,
  transientPropCheck
)<{ $copied: boolean }>(
  ({ $copied }) => css`
    ${copyIconCss}
    stroke-dashoffset: ${$copied ? -50 : 0};
  `
)

const CheckIcon = styled(
  Check,
  transientPropCheck
)<{ $copied: boolean }>(
  ({ theme, $copied }) => css`
    ${copyIconCss}
    color: ${theme.palette.success.main};
    stroke-dashoffset: ${$copied ? 0 : -50};
  `
)

interface CopyIconProps {
  copied: boolean
}

const CopyIcon: React.FC<CopyIconProps> = ({ copied, ...props }) => (
  <Box width={16} position="relative" ml={1} {...props}>
    <CopyIconSvg $copied={copied} />
    <CheckIcon $copied={copied} />
  </Box>
)

export default CopyIcon
