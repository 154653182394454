import css from '@emotion/css'
import styled from '@emotion/styled'
import { Suspense, lazy } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import Header from 'src/modules/header'
import { Routes } from 'src/utils/routes'
import Footer from 'src/modules/_core/Footer'
import Topbar from 'src/modules/_core/Topbar'
import NotFound from 'src/modules/_core/NotFound'
import Toast from 'src/components/feedback/Toast'
import PageLoading from 'src/modules/_core/PageLoading'
import SkipLink from 'src/components/navigation/SkipLink'
import { useEagerConnect } from 'src/hooks/useEagerConnect'
import ScrollToTop from 'src/components/navigation/ScrollToTop'
import AuthenticatedRoute from 'src/modules/_core/AuthenticatedRoute'
import ConnectWalletDialog from 'src/modules/_core/ConnectWalletDialog'

const Faq = lazy(() => import('src/modules/faq'))
const Home = lazy(() => import('src/modules/home'))
const About = lazy(() => import('src/modules/about'))
const Dashboard = lazy(() => import('src/modules/dashboard'))
const Collections = lazy(() => import('src/modules/Collections'))
const DiscordVerify = lazy(() => import('src/modules/DiscordVerify'))
const TermsAndConditions = lazy(() => import('src/modules/TermsAndConditions'))

const Layout = styled.div`
  display: flex;
  overflow: hidden;
  min-height: 100vh;
  flex-direction: column;
`

const Background = styled.div(
  ({ theme }) => css`
    background: ${theme.palette.gradients.background};
  `
)

const Main = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const App: React.FC = () => {
  useEagerConnect()

  return (
    <Router>
      <ScrollToTop />
      <SkipLink href="#main-pass">Skip to main content</SkipLink>
      <Topbar />
      <Background>
        <Layout>
          <Suspense fallback={<PageLoading />}>
            <Header />
            <Main id="main-pass">
              <Switch>
                <Route path={Routes.Home} exact>
                  <Home />
                </Route>
                <Route path={Routes.About} exact>
                  <About />
                </Route>
                <Route path={Routes.Faq} exact>
                  <Faq />
                </Route>
                <Route path={Routes.Collections} exact>
                  <Collections />
                </Route>
                <Route path={Routes.Terms} exact>
                  <TermsAndConditions />
                </Route>
                <AuthenticatedRoute path={Routes.DiscordVerify} exact>
                  <DiscordVerify />
                </AuthenticatedRoute>
                <Route path={Routes.Dashboard}>
                  <Dashboard />
                </Route>
                <Route>
                  <NotFound />
                </Route>
              </Switch>
            </Main>
          </Suspense>
          <Footer />
        </Layout>
      </Background>
      <Toast />
      <ConnectWalletDialog />
    </Router>
  )
}

export default App
