import css from '@emotion/css'
import styled from '@emotion/styled'
import { Typography, Container } from '@mui/material'

import Button from 'src/components/button/Button'
import { CORTEX_BASE_URL, BURGER_MENU_BREAKPOINT } from 'src/utils/constants'

import astoImg from 'src/assets/images/asto-logo-transparent.png'

const Wrapper = styled.div(
  ({ theme }) => css`
    width: 100%;
    padding: ${theme.spacing(2, 0)};
    background: ${theme.palette.gradients.moody};
    ${theme.breakpoints.down(BURGER_MENU_BREAKPOINT)} {
      display: none;
    }
  `
)

const StyledContainer = styled(Container)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    img {
      width: 26px;
      height: 26px;
    }
    a {
      border-radius: 8px;
      border-width: 1px !important;
      padding: ${theme.spacing(1)} !important;
      font-size: ${theme.typography.pxToRem(10)};
    }
  `
)

const Topbar: React.FC = () => (
  <Wrapper>
    <StyledContainer>
      <img src={astoImg} alt="ASTO Token logo" />
      <Typography variant="body2" color="text.secondary" mx={2}>
        ASTO, the official token of AIFA and ASM is live!
      </Typography>
      <Button
        size="small"
        role="link"
        color="inherit"
        target="_blank"
        variant="outlined"
        href={CORTEX_BASE_URL}
        rel="noopener noreferrer"
      >
        ASM Cortex
      </Button>
    </StyledContainer>
  </Wrapper>
)

export default Topbar
