import { Web3ReactProvider } from '@web3-react/core'
import { ThemeProvider as EmotionProvider } from '@emotion/react'
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from '@apollo/client'

import { CssBaseline } from '@mui/material'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'

import theme from 'src/styles/theme'
import { getLibrary } from 'src/utils/web3'
import GlobalStyles from 'src/styles/GlobalStyles'

const CONFIGURATOR_KEY = localStorage.getItem('X-ASM-CONFIGURATOR-KEY')
const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_ENDPOINT,
  headers: {
    authorization: CONFIGURATOR_KEY ? `${CONFIGURATOR_KEY}` : '',
  },
})

const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
})

const Providers: React.FC = ({ children }) => (
  <Web3ReactProvider getLibrary={getLibrary}>
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <EmotionProvider theme={theme}>
          <StyledEngineProvider injectFirst>
            <GlobalStyles />
            <CssBaseline />
            {children}
          </StyledEngineProvider>
        </EmotionProvider>
      </ThemeProvider>
    </ApolloProvider>
  </Web3ReactProvider>
)

export default Providers
