import css from '@emotion/css'
import styled from '@emotion/styled'

import { Typography, Container as MuiContainer } from '@mui/material'

import Background from 'src/components/layout/Background'
import Button from 'src/components/button/Button'

import landscapeCharacters from 'src/assets/images/maintenance/landscape-characters.png'
import { ReactComponent as Lines } from 'src/assets/images/fieldlines.svg'

const COLUMN_BREAKPOINT = 1115

const Wrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    position: relative;
    margin: ${theme.spacing(8, 0, 0)};

    ${theme.breakpoints.down(COLUMN_BREAKPOINT)} {
      margin: 0;
      flex-direction: column-reverse;
    }
  `
)

const Container = styled(MuiContainer)(
  ({ theme }) => css`
    z-index: 1;
    display: flex;

    ${theme.breakpoints.down(COLUMN_BREAKPOINT)} {
      flex-direction: column-reverse;
    }
  `
)

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
`

const ButtonContainer = styled.div`
  text-align: center;
`

const Image = styled.img`
  margin: 0 auto;
`

const FieldLines = styled(Lines)`
  top: 0;
  position: absolute;
`

const MaintenanceContent: React.FC = () => (
  <Background>
    <Wrapper>
      <Container>
        <Content>
          <Image src={landscapeCharacters} />
          <Typography variant="h1" color="textSecondary" align="center" mb={2}>
            We&apos;ll be back soon!
          </Typography>
          <Typography
            gutterBottom
            variant="body1"
            align="center"
            sx={{ color: 'text.mid' }}
            mb={3}
          >
            The dashboard is having a rest right now, check our Twitter or
            Discord for when it’s back up and running.
          </Typography>
          <ButtonContainer>
            <Button
              color="primary"
              href="https://twitter.com/altstatemachine"
              target="_blank"
              variant="contained"
            >
              Twitter
            </Button>
            &nbsp;&nbsp;
            <Button
              color="primary"
              href="https://discord.com/invite/4jSWquAq4j"
              variant="contained"
              target="_blank"
            >
              Discord
            </Button>
          </ButtonContainer>
        </Content>
      </Container>
      <FieldLines />
    </Wrapper>
  </Background>
)

export default MaintenanceContent
