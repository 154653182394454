import css from '@emotion/css'
import styled from '@emotion/styled'

import {
  Fade,
  IconButton,
  DialogProps as MuiDialogProps,
  Dialog as MuiDialog,
} from '@mui/material'
import { Close } from '@mui/icons-material'
import React from 'react'

const CloseButton = styled(IconButton)(
  ({ theme }) => css`
    top: 20px;
    right: 20px;
    position: absolute;
    background-color: #fff;

    svg {
      color: ${theme.palette.text.primary};
      font-size: ${theme.typography.pxToRem(32)};
    }

    @media (hover: hover) {
      :hover {
        background-color: #d4d4d4;
      }
    }
  `
)

export interface DialogProps extends MuiDialogProps {
  disableCloseButton?: boolean
}

const Dialog: React.FC<DialogProps> = ({
  onClose,
  children,
  disableCloseButton = false,
  ...props
}) => {
  const handleCloseClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    onClose && onClose(e, 'escapeKeyDown')
  }

  return (
    <MuiDialog {...props} onClose={onClose} TransitionComponent={Fade}>
      {!disableCloseButton && (
        <CloseButton onClick={handleCloseClick}>
          <Close />
        </CloseButton>
      )}
      {children}
    </MuiDialog>
  )
}

export default Dialog
