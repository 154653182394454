import { useCallback } from 'react'
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from '@web3-react/injected-connector'
import {
  WalletConnectConnector,
  UserRejectedRequestError as UserRejectedRequestErrorWC,
} from '@web3-react/walletconnect-connector'
import { captureException } from '@sentry/react'

import {
  CHAIN_NAME,
  connectors,
  ConnectorNames,
  setConnectorToStorage,
  removeConnectorFromStorage,
} from 'src/utils/web3'
import { useToast } from 'src/hooks/store/useToast'
import { useWeb3React } from 'src/hooks/useWeb3React'

export const useWallet = () => {
  const { openToast } = useToast()
  const { activate, deactivate, ...rest } = useWeb3React()

  const connectWallet = useCallback(
    (connectorName: ConnectorNames) => {
      setConnectorToStorage(connectorName)

      const connector = connectors[connectorName]

      if (connector) {
        activate(connector, async (error: Error) => {
          removeConnectorFromStorage()

          if (error instanceof NoEthereumProviderError) {
            openToast({ message: 'No provider was found', severity: 'error' })
          } else if (
            error instanceof UserRejectedRequestErrorWC ||
            error instanceof UserRejectedRequestErrorInjected ||
            // For wallet-link connector
            error.message === 'User denied account authorization'
          ) {
            if (connector instanceof WalletConnectConnector) {
              const walletConnector = connector as WalletConnectConnector
              walletConnector.walletConnectProvider = null
            }
            openToast({
              severity: 'error',
              message: 'Please authorise to connect wallet',
            })
          } else if (error.message.includes('Unsupported chain id')) {
            openToast({
              severity: 'error',
              message: `Sorry, human error, please change wallet to Ethereum ${CHAIN_NAME}`,
            })
          } else {
            openToast({
              message: error.message,
              severity: 'error',
            })
            captureException(error)
          }
        })
      } else {
        openToast({ message: 'Unable to find connector', severity: 'error' })
      }
    },
    [activate, openToast]
  )

  const disconnectWallet = useCallback(() => {
    deactivate()

    // This key is set by @web3-react/walletconnect-connector
    if (localStorage.getItem('walletconnect')) {
      const walletconnect = connectors[
        ConnectorNames.WalletConnect
      ] as WalletConnectConnector

      walletconnect.close()
      walletconnect.walletConnectProvider = null
    }

    removeConnectorFromStorage()
  }, [deactivate])

  return { connectWallet, disconnectWallet, ...rest }
}
