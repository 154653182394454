import React from 'react'
import ReactDOM from 'react-dom'

import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'

import App from 'src/modules/App'
import Providers from 'src/providers'
import reportAccessibility from 'src/utils/reportAccessibility'
import reportWebVitals from 'src/utils/reportWebVitals'
import { initAnalytics } from './utils/ga'

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: Number(process.env.REACT_APP_SENTRY_SAMPLE_RATE || 1.0),
  })
}

if (process.env.REACT_APP_LOGROCKET_ID) {
  LogRocket.init(process.env.REACT_APP_LOGROCKET_ID)
  setupLogRocketReact(LogRocket)

  LogRocket.getSessionURL(sessionURL => {
    Sentry.configureScope(scope => {
      scope.setExtra('sessionURL', sessionURL)
    })
  })
}

window.addEventListener('load', () => {
  if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
    initAnalytics(process.env.REACT_APP_GOOGLE_ANALYTICS_ID)
  }
})

ReactDOM.render(
  // Strict mode checks are run in development mode only; they do not impact the production build.
  <React.StrictMode>
    <Providers>
      <App />
    </Providers>
  </React.StrictMode>,
  document.getElementById('root')
)

if (process.env.NODE_ENV !== 'production') {
  reportAccessibility(React, ReactDOM)

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  // Can also be used in production.
  reportWebVitals()
}
