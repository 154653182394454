export enum Routes {
  Home = '/',
  Faq = '/faq',
  About = '/about',
  Dashboard = '/dashboard',
  Collections = '/collections',
  Terms = '/terms-and-conditions',
  DiscordVerify = '/claim-roles/:token',
}

export enum DashboardRoutes {
  Brain = '/dashboard/brain/:tokenId',
  Character = '/dashboard/character/:tokenId',
  Configure = '/dashboard/configure/:tokenId',
}
