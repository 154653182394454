export const BURGER_MENU_BREAKPOINT = 1150

export const GLITCH_BOX_TOKEN_ID = 2384

export type PurchaseStatus = 'processing' | 'successful'

export const CORTEX_BASE_URL = 'https://cortex.alteredstatemachine.xyz'

export enum collections {
  BRAINS = 'https://opensea.io/collection/asm-brains',
  BOXES = 'https://opensea.io/collection/asm-aifa-genesis',
  ALLSTARS = 'https://opensea.io/collection/asm-aifa-all-stars',
}

export const openseaLinks = {
  MAINNET: 'https://opensea.io/assets',
  TESTNET: 'https://testnets.opensea.io/assets',
}

export const GLITCH_WALLET_ADDRESS = process.env
  .REACT_APP_GLITCH_WALLET as string
