import { Route, RouteProps } from 'react-router'

import { useStatusQuery } from 'src/generated/hooks'
import { useWeb3React } from 'src/hooks/useWeb3React'
import Maintenance from 'src/modules/dashboard/maintenance'
import UnauthorisedState from 'src/modules/_core/UnauthorisedState'

const AuthenticatedRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const { active } = useWeb3React()

  const { data } = useStatusQuery()

  const isUnderMaintenance =
    data?.status?.flags?.isConfiguratorEnabled === false // Needs to be false and not undefined (loading)

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (isUnderMaintenance) {
          return <Maintenance />
        }
        if (!active) {
          return <UnauthorisedState location={location} />
        }
        return children
      }}
    />
  )
}

export default AuthenticatedRoute
