import css from '@emotion/css'
import styled from '@emotion/styled'

import { motion } from 'framer-motion'

import { ReactComponent as Shield } from 'src/assets/images/shield-logo.svg'

const ShieldContainer = styled(motion.div)(
  ({ theme }) => css`
    width: 110px;
    margin-top: -${theme.spacing(12)};
  `
)

const transition = {
  duration: 2,
  loop: Infinity,
  ease: 'easeInOut',
}

const animate = { scale: [0.8, 1, 0.8], opacity: [0.4, 1, 0.4] }

const ShieldLoadingIcon = () => (
  <ShieldContainer animate={animate} transition={transition}>
    <Shield width="100%" />
  </ShieldContainer>
)

export default ShieldLoadingIcon
