import css from '@emotion/css'
import { useState } from 'react'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'

import { Container, Typography } from '@mui/material'

import { Routes } from 'src/utils/routes'
import { formatAccount } from 'src/utils/helpers'
import CopyIcon from 'src/components/icons/CopyIcon'

import asmLogoSrc from 'src/assets/images/asm-logo.png'

const Content = styled.div(
  ({ theme }) => css`
    display: flex;
    justify-content: space-between;
    margin: ${theme.spacing(6, 0)};

    ${theme.breakpoints.down('md')} {
      align-items: center;
      flex-direction: column;
    }

    ${theme.breakpoints.down('sm')} {
      margin: ${theme.spacing(3, 0)};
    }
  `
)

const MainContent = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    ${theme.breakpoints.down('md')} {
      align-items: center;
      flex-direction: column-reverse;
    }
  `
)

const Logo = styled.img(
  ({ theme }) => css`
    width: 130px;
    margin-right: ${theme.spacing(3)};

    ${theme.breakpoints.down('md')} {
      margin: ${theme.spacing(0, 0, 3)};
    }
  `
)

const TextContent = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;

    ${theme.breakpoints.down('md')} {
      flex-direction: column;
    }
  `
)

const Anchor = styled(Link)(
  ({ theme }) => css`
    margin: ${theme.spacing(0, 0, 0, 3)};
    transition: border-color 0.3s ease-out;
    border-color: ${theme.palette.secondary.dark};

    p {
      transition: color 0.3s ease-out;
    }

    :hover {
      border-color: #fff;
    }

    ${theme.breakpoints.down('md')} {
      margin: ${theme.spacing(1, 0, 0)};
    }
  `
)

const Divider = styled.hr(
  ({ theme }) => css`
    border: 1px solid transparent;
    border-top: 1px solid ${theme.palette.secondary.main};
    opacity: 50%;
    height: 0px;
    margin: 0px;
    margin-top: -1px;
    padding: 0px;
    background-color: transparent;
  `
)

const StyledCopyIcon = styled(CopyIcon)(
  ({ theme }) => css`
    margin-right: ${theme.spacing(0.5)};
    svg path {
      stroke: ${theme.palette.secondary.dark};
    }
  `
)

const year = new Date().getFullYear()

const astoAddress = '0x823556202e86763853b40e9cDE725f412e294689'

const Footer: React.FC = () => {
  const [copied, setCopied] = useState(false)

  const handleCopy = () => {
    setCopied(true)
    navigator.clipboard.writeText(astoAddress)

    setTimeout(() => {
      setCopied(false)
    }, 1000)
  }

  return (
    <footer>
      <Container>
        <Divider />
        <Content>
          <Logo src={asmLogoSrc} alt="Altered State Machine logo" />
          <MainContent>
            <TextContent>
              <Typography
                variant="caption"
                sx={{
                  fontWeight: 500,
                  textAlign: 'center',
                  color: 'secondary.dark',
                }}
              >
                &copy; Copyright Altered State Machine Ltd {year}, All Rights
                Reserved
              </Typography>
              <Anchor to={Routes.Terms}>
                <Typography variant="caption" color="secondary.dark">
                  Terms & Conditions
                </Typography>
              </Anchor>
            </TextContent>
            <Typography
              tabIndex={0}
              role="button"
              component="p"
              variant="caption"
              onClick={handleCopy}
              color="secondary.dark"
              sx={{
                display: 'flex',
                cursor: 'pointer',
                mb: { xs: 1.5, md: 0 },
                mt: { xs: 0, md: 1.5 },
              }}
            >
              <StyledCopyIcon copied={copied} />
              ASTO Contract address: {formatAccount(astoAddress)}
            </Typography>
          </MainContent>
        </Content>
      </Container>
    </footer>
  )
}

export default Footer
