declare namespace window {
  let dataLayer: object[]
}

/**
 * Important: For some reason this needs to remain a function and
 * arguments of type IArguments needs to be pushed to dataLayer.
 * De-constructed args will not work.
 * @see IArguments
 */
function gtag(..._args: any) {
  if (!window.dataLayer) {
    console.warn('Google analytics library not loaded')
    window.dataLayer = window.dataLayer || []
  }

  window.dataLayer.push(arguments)
}

export const initAnalytics = (analyticsId: string) => {
  gtag('js', new Date())
  gtag('config', analyticsId)
}
