import css from '@emotion/css'
import { Location } from 'history'
import styled from '@emotion/styled'

import { Box, Typography } from '@mui/material'

import gap from 'src/utils/cssGap'
import Button from 'src/components/button/Button'
import Background from 'src/components/layout/Background'
import { useWalletsDialog } from 'src/hooks/store/useWalletDialog'
import CharacterWithShadow from 'src/components/character/CharacterWithShadow'

import player1 from 'src/assets/images/originals/Player1-Shrugging.png'
import { Routes } from 'src/utils/routes'

const ComponentContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    ${gap.col(theme.spacing(3))}
    color: ${theme.palette.text.mid};
    padding-top: ${theme.spacing(6)};
    padding-bottom: ${theme.spacing(6)};
  `
)

const UnauthorisedState: React.FC<{ location: Location }> = ({ location }) => {
  const { openWalletsDialog } = useWalletsDialog()

  const isDashboardRoute = location.pathname.includes(Routes.Dashboard)
  const title = isDashboardRoute ? 'Dashboard' : 'Verify your role'

  return (
    <Background>
      <ComponentContainer>
        <Typography
          variant="h2"
          align="center"
          color="textSecondary"
          sx={{
            mb: 6,
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
          }}
        >
          {title}
        </Typography>
        <Box width={175}>
          <CharacterWithShadow src={player1} alt="Player 1 shrugging" />
        </Box>
        <Typography
          variant="h3"
          align="center"
          sx={{ maxWidth: { xs: '100%', sm: 360 } }}
        >
          Your Wallet is not connected
        </Typography>
        <Button variant="contained" color="primary" onClick={openWalletsDialog}>
          Connect Wallet
        </Button>
      </ComponentContainer>
    </Background>
  )
}

export default UnauthorisedState
