import styled from '@emotion/styled'
import {
  Button as MuiButton,
  ButtonProps,
  CircularProgress,
} from '@mui/material'

export type { ButtonProps } from '@mui/material'

const StyledCircularProgress = styled(CircularProgress)`
  z-index: 100;
  color: #fff;
  position: absolute;
  left: calc(50% - 12px);
`

export const Button = <C extends React.ElementType>({
  to,
  children,
  loading = false,
  ...props
}: ButtonProps<C, { component?: C }>) => (
  <MuiButton {...props} to={to} role={to && 'link'}>
    {children}
    {loading && <StyledCircularProgress size={30} />}
  </MuiButton>
)

export default Button
