import { useState } from 'react'

import player1Outline from 'src/assets/images/originals/Player1-Outline.png'

interface CharacterWithErrorProps {
  src: string
  alt?: string
  onError?: () => void
}

const CharacterWithError: React.FC<CharacterWithErrorProps> = ({
  src,
  alt,
  onError,
  ...props
}) => {
  const [image, setImage] = useState(src)

  const handleImageError = () => {
    setImage(player1Outline)
    onError && onError()
  }

  return <img {...props} src={image} alt={alt} onError={handleImageError} />
}

export default CharacterWithError
